<template>
  <div>
    <SharePaymentLink
        :link="linkToCopy"
        :dialog="shareDialog"
        @close-dialog="closeDialog"
    ></SharePaymentLink>
    <div v-if="isMobile" style="margin-top: 20px">
      <div style="margin-top: 16px;width: 100%;height: 50vh;overflow-y: auto;overflow-x: hidden">
        <v-expansion-panels flat style="width: 100%">
          <v-expansion-panel
              v-for="(link, index) in fixedPaymentLinks"
              :key="index"
              style="margin-top: 12px"
          >
            <div>
              <div style="display: flex;justify-content: space-between;align-items: center;font-size: 14px;color: #464646;font-weight: 500">
                <p class="break-word">{{ link.productName !== null ? link.productName : 'N/A' }}</p>
                <div style="margin-left: 12px;display: flex;align-items: center;gap: 12px">
                  <p> ৳{{ link.amount }}</p>
                  <v-menu :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" size="16px">mdi-dots-vertical</v-icon>
                    </template>
                    <v-list elevation="0" style="font-size: 14px;font-weight: 400;color: #464646">
                      <v-list-item @click="$router.push(`payment-links/edit/${link.paymentLinkId}`)">
                        Edit
                      </v-list-item>
                      <v-list-item @click="sharePaymentLink(prefix + link.urlPath)">
                        Share
                      </v-list-item>
                      <v-list-item @click="softDeletePaymentLink(link.paymentLinkId, link.status)">
                        {{ link.status === "active" ? "Deactivate" : "Activate" }}
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
              <div class="break-word">
                <div v-if="link.status === 'active'">
                  <a :href="prefix + link.urlPath" target="_blank" style="color: #777;font-size: 14px;font-weight: 400">
                    {{ (prefix + link.urlPath) }}
                  </a>
                  <v-icon @click="copyText(prefix + link.urlPath)" color="primary" size="16">
                    mdi-content-copy
                  </v-icon>
                </div>
                <div v-else style="color: #777;font-size: 14px;font-weight: 400">{{ prefix + link.urlPath }}</div>
              </div>
            </div>

            <v-expansion-panel-content>
              <div style="display: grid; grid-template-columns: 4fr 6fr;row-gap: 16px">
                <div style="color: #464646; font-size: 14px;font-weight: 400">
                  <p>Created At</p>
                  <p>Expiry date</p>
                  <p>Link ID</p>
                  <p>Item(s) Sold</p>
                </div>

                <div style="color: #777; font-size: 14px;font-weight: 400">
                  <p>{{ link.createdDate }}</p>
                  <p>{{ link.expiryDate }}</p>
                  <p>{{ link.paymentLinkId.slice(0, 16) + ".." }}
                    <v-icon @click="copyText(link.paymentLinkId)" color="primary" size="16">
                      mdi-content-copy
                    </v-icon>
                  </p>
                  <p>{{ link.availableStock }}</p>
                </div>
              </div>
            </v-expansion-panel-content>

            <v-expansion-panel-header style="margin:8px 0 12px 0">
              <div :class="`status-${link.status}`" style="font-size: 14px">
                {{ link.status | capitalize }}
              </div>
            </v-expansion-panel-header>
            <v-divider style="color: #EEE; width: 100%;"></v-divider>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <div v-else>
      <v-data-table
          :loading="isLoading"
          :headers="headers"
          :items="fixedPaymentLinks"
          fixed-header
          loading-text="Loading... Please wait"
          no-data-text="No results found"
          :hide-default-footer="true"
          disable-sort
          disable-pagination
          class="list-table"
          item-class="item-class"
          height="300"
      >
        <template v-slot:item.urlPath="{ item }">
          <a
              :href="prefix + item.urlPath"
              target="_blank"
              v-if="item.status === 'active'"
              class="break-word"
          >
            {{ prefix + item.urlPath }}
          </a>
          <div v-else class="break-word" style="color: #777777">{{ prefix + item.urlPath }}</div>
          <v-btn
              v-if="item.status === 'active'"
              text
              icon
              color="primary"
              @click="copyText(prefix + item.urlPath)"
          >
            <v-icon size="18">mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.status="{ item }">
          <div :class="`status-${item.status}`">
            {{ item.status | capitalize }}
          </div>
        </template>
        <template v-slot:item.stockCount="{ item }">
          <div v-if="item.stockCount !== null">
            {{ item.transactionCount }}/{{ item.stockCount }}
          </div>
          <div v-else>N/A</div>
        </template>
        <template v-slot:item.others="{ item }">
          <div>
            <v-btn
                depressed
                color="transparent"
                style="color: #e2136e; font-size: 12px;"
                @click="$router.push(`payment-links/details/${item.paymentLinkId}`)"
            >
              Details
            </v-btn>
            <v-btn v-if="item.productId"
                depressed
                color="transparent"
                style="color: #e2136e; font-size: 12px;"
                @click="$router.push(`payment-links/edit/${item.paymentLinkId}`)"
            >
              <v-icon size="18">
                mdi-pencil
              </v-icon>
              Edit
            </v-btn>
          </div>
          <div>
            <v-btn
                depressed
                color="transparent"
                style="color: #e2136e; font-size: 12px;"
                @click="sharePaymentLink(prefix + item.urlPath)"
            >
              <v-icon size="18">
                mdi-share
              </v-icon>
              Share
            </v-btn>
            <v-btn
                depressed
                color="transparent"
                style="color: #e2136e; font-size: 12px;"
                @click="softDeletePaymentLink(item.paymentLinkId, item.status)"
            >
              <v-icon size="16">
                mdi-wrench
              </v-icon>
              {{ item.status === "active" ? "Deactivate" : "Activate" }}
            </v-btn>
          </div>
        </template>
        <template v-slot:item.paymentLinkId="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div style="display: flex; align-items: center;">
              <span
                  v-bind="attrs"
                  v-on="on"
                  style="display: inline-block;width: 50px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;"
              >{{ item.paymentLinkId }}</span
              >

                <v-btn
                    text
                    icon
                    color="primary"
                    @click="copyText(item.paymentLinkId)"
                >
                  <v-icon size="18">mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ item.paymentLinkId }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.amount="{ item }"> ৳{{ item.amount }}</template>
        <template v-slot:item.shortUrl="{ item }">
          <link-share :paymentLink="prefix + item.urlPath"></link-share>
        </template>
      </v-data-table>
    </div>
    <pagination
        :tokens="fixedPaymentTokens"
        :item-count="fixedPaymentLinks.length"
        :is-disabled="isLoading"
        @next="getNext"
        @previous="getPrevious"
    ></pagination>
  </div>
</template>

<script>
import LinkShare from "@/components/dashboard/paymentLink/LinkShare.vue";
import SharePaymentLink from "./SharePaymentLink.vue";
import Pagination from "cpp-js-util/components/common/Pagination";

export default {
  name: "PaymentLinkList",
  components: {LinkShare, SharePaymentLink, Pagination},
  props: ["searchString"],
  created() {
    this.getFixedPaymentLinks();
  },
  computed: {
    fixedPaymentPageListTokens() {
      return this.$store.getters.getFixedPaymentPageListTokens;
    },
    isMobile() {
      return this.$vuetify.breakpoint.width <= 1000;
    },
  },
  data() {
    return {
      isLoading: false,
      prefix: process.env.VUE_APP_CUSTOMER_PORTAL_URL,
      headers: [
        {
          text: "Name",
          width: "10%",
          value: "productName",
          class: "table-header-text",
        },
        {
          text: "Link",
          width: "20%",
          value: "urlPath",
          class: "table-header-text",
        },
        {
          text: "Created At",
          width: "6%",
          value: "createdDate",
          class: "table-header-text",
        },
        {
          text: "Expiry Date",
          width: "6%",
          value: "expiryDate",
          class: "table-header-text",
        },
        {
          text: "Link ID",
          width: "8%",
          value: "paymentLinkId",
          class: "table-header-text",
        },
        {text: "Status", value: "status", class: "table-header-text"},
        {text: "Item(s) Sold", value: "stockCount", class: "table-header-text"},
        {text: "Amount", value: "amount", class: "table-header-text"},
        {text: "Others", value: "others", class: "table-header-text"},
      ],
      fixedPaymentLinks: [],
      fixedPaymentTokens: [""],
      payload: {nextToken: ""},
      linkToCopy: null,
      shareDialog: false,
    };
  },
  watch: {
    searchString: function (newVal, oldVal) {
      // watch it
      this.$nextTick(() => {
        console.log(newVal, oldVal);
        this.getFixedPaymentLinks();
      });
    },
  },
  methods: {
    closeDialog() {
      console.log("got here");
      this.shareDialog = false;
      this.linkToCopy = null;
    },
    sharePaymentLink(link) {
      this.linkToCopy = link;
      this.shareDialog = true;
    },
    async getFixedPaymentLinks() {
      this.isLoading = true;
      this.$feedback.showLoading();
      this.payload.searchString = this.searchString.trim();

      try {
        const list = await this.$cppClient.post(
            "payment-link/list",
            this.payload
        );

        this.fixedPaymentLinks = list.data.result;
        this.fixedPaymentTokens = ["", list.data.nextToken];

        this.isLoading = false;
      } catch (err) {
        this.$feedback.showFailed(err);
      }
      this.isLoading = false;
      this.$feedback.hideLoading();
    },
    async getNext(requestBody) {
      this.isLoading = true;
      requestBody.searchString = this.searchString;
      try {
        const list = await this.$cppClient.post(
            "payment-link/list",
            requestBody
        );
        this.fixedPaymentLinks = list.data.result;
        this.fixedPaymentTokens.push(list.data.nextToken);
      } catch (err) {
        this.$feedback.showFailed(err);
      }
      this.isLoading = false;
      this.$feedback.hideLoading();
    },
    async getPrevious(requestBody) {
      this.isLoading = true;
      requestBody.searchString = this.searchString;
      try {
        const list = await this.$cppClient.post(
            "payment-link/list",
            requestBody
        );
        this.fixedPaymentLinks = list.data.result;
        this.fixedPaymentTokens.pop();
      } catch (err) {
        this.$feedback.showFailed(err);
      }
      this.isLoading = false;
      this.$feedback.hideLoading();
    },
    async softDeletePaymentLink(id, status) {
      this.isLoading = true;
      this.$feedback.showLoading();
      const payloadStatus = status === "active" ? "inactive" : "active";
      try {
        await this.$cppClient.post("payment-link/update", {
          paymentLinkId: id,
          status: payloadStatus,
        });
        this.fixedPaymentLinks = [];
        await this.$feedback.showSuccessMessage("Payment Link status updated.");
        this.getFixedPaymentLinks();
        this.isLoading = false;
      } catch (err) {
        this.$feedback.showFailed(err);
      }
      this.isLoading = false;
      this.$feedback.hideLoading();
    },
    copyText(textToCopy) {
      const copied = navigator.clipboard.writeText(textToCopy);
      if (copied) {
        this.$feedback.showShortToast("Copied!");
      }
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}

.break-word {
  word-break: break-all;
}

.list-table {
  font-size: 14px;
  max-height: 50vh;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px;
}

::v-deep .theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #f9f9f9 !important;
  box-shadow: none !important;
}

.status-active {
  color: #01846C;
}

.status-inactive {
  color: #777;
}

>>> .v-expansion-panel-content__wrap {
  padding: 0;
}

>>> .v-expansion-panel-header {
  padding: 0;
}

>>> .v-expansion-panel-header {
  min-height: unset;
}

>>> .v-expansion-panel-header__icon > i {
  font-size: 16px;
}
</style>
