<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent>
      <v-card>
        <div>
          <div class="header-container">
            <div class="share-link-text"> Share Link </div>
            <v-icon @click="closeDialog" color="primary">mdi-close</v-icon>
          </div>
          <div class="link-text"> Link </div>
          <div class="link-container">
            {{ this.link }}
          </div>
          <div class="btn-container">
            <v-btn
              depressed
              color="#FAEDF3"
              style="color: #e2136e;"
              @click="copyText"
            >
              <v-icon size="16px">
                mdi-content-copy
              </v-icon>
              Copy Link
            </v-btn>
          </div>
          <div
            style="display: flex; flex-direction: column; align-items: center; margin-bottom: 20px;"
          >
            <span class="share-via-text"> Share via </span>
            <div>
              <ShareNetwork
                network="whatsapp"
                :url="link"
                :title="shareTitle"
                :description="shareDescription"
                :quote="shareQuote"
                :hashtags="shareHashtags"
              >
                <img
                  style="width: 40px; height: 40px; margin: 10px;"
                  @click="onShareIconClick"
                  src="@/assets/image/logo-whatsapp.svg"
                />
              </ShareNetwork>
              <ShareNetwork
                network="facebook"
                :url="link"
                :title="shareTitle"
                :description="shareDescription"
                :quote="shareQuote"
                :hashtags="shareHashtags"
              >
                <img
                  style="width: 40px; height: 40px; margin: 10px;"
                  @click="onShareIconClick"
                  src="@/assets/image/logo-facebook.svg"
                />
              </ShareNetwork>
              <ShareNetwork
                network="messenger"
                :url="link"
                :quote="shareQuote"
                :hashtags="shareHashtags"
              >
                <img
                  style="width: 40px; height: 40px; margin: 10px;"
                  @click="onShareIconClick"
                  src="@/assets/image/logo-messenger.svg"
                />
              </ShareNetwork>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "SharePaymentLink",
  props: ["link", "dialog"],
  computed: {
    shareTitle() {
      return process.env.VUE_APP_SHARE_TITLE;
    },
    shareDescription() {
      return process.env.VUE_APP_SHARE_DESCRIPTION;
    },
    shareQuote() {
      return process.env.VUE_APP_SHARE_QUOTE;
    },
    shareHashtags() {
      return process.env.VUE_APP_SHARE_HASHTAGS;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
      console.log("Here i'm ");
    },
    showUploadInfoDialog() {
      this.dialog = true;
    },
    copyText() {
      const copied = navigator.clipboard.writeText(this.link);
      if (copied) {
        this.$feedback.showShortToast("Copied!");
      }
    },
    onShareIconClick() {
      this.$gtag.event("Payment Link shared", {
        event_category:
          this.$route.name === "dashboard"
            ? "Dashboard Page"
            : "Payment Links Page",
      });
    },
  },
};
</script>

<style scoped>
>>> .v-dialog {
  margin: 0;
  width: 246px;
}

>>> .v-card {
  padding: 12px;
}

.header-container {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.share-link-text {
  font-size: 14px;
  font-weight: 600;
  color: #464646;
}

.link-text {
  font-size: 14px;
  font-weight: 500;
  color: #777;
  margin-bottom: 8px;
}

.link-container {
  width: 218px;
  height: auto;
  background: #F3F3F3;
  border: 1px solid #DBDBDB;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 8px;

  color: #464646;
  font-weight: 400;
  font-size: 12px;
}

.btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
}

.v-btn {
  width: 114px;
  font-size: 14px;
}

.share-via-text {
  color: #464646;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 8px;
}

@media screen and (min-width: 768px) {
  >>> .v-dialog {
    margin: 0;
    width: 481px;
  }

  >>> .v-card {
    padding: 40px;
  }

  .header-container {
    margin-bottom: 40px;
  }

  .share-link-text {
    font-size: 24px;
  }

  .link-text {
    font-size: 18px;
    margin-bottom: 13px;
  }

  .link-container {
    width: 401px;
    margin-bottom: 27px;
    padding: 8px 16px;
    font-size: 14px;
  }

  .btn-container {
    margin-bottom: 27px;
  }

  .v-btn {
    width: 172px;
    font-size: 16px;
  }

  .share-via-text {
    font-size: 16px;
    margin-bottom: 16px;
  }
}
</style>
