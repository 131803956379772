<template>
  <div>
    <div v-if="isMobile" style="width: 100%;max-height: 50vh;overflow-y: auto;overflow-x: hidden">
      <v-expansion-panels flat style="width: 100%">
        <v-expansion-panel
            v-for="(item, index) in pages"
            :key="index"
            style="margin-top: 12px"
        >
          <div>
            <div style="display: flex;justify-content: space-between;align-items: center;font-size: 14px;color: #464646;font-weight: 500">
              <p>{{ item.title !== null ? item.title : 'N/A' }}</p>
              <v-menu :close-on-content-click="true">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" size="16px">mdi-dots-vertical</v-icon>
                </template>
                <v-list style="font-size: 14px;font-weight: 400;color: #464646">
                  <v-list-item @click="editPage(item.pageId, item.editable)">
                    Edit
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div>
              <div v-if="item.status === 'active'">
                <a :href="item.pageLink" target="_blank" style="color: #777;font-size: 14px;font-weight: 400">
                  {{ item.pageLink.slice(0, 32) + '...' }}
                </a>
                <v-icon @click="copyText(item.pageLink)" color="primary" size="16">
                  mdi-content-copy
                </v-icon>
              </div>
              <span v-else style="color: #777;font-size: 14px;font-weight: 400">{{ item.pageLink }}</span>
            </div>
          </div>

          <v-expansion-panel-content>
            <span style="color: #464646; font-size: 14px;font-weight: 400">Created at  {{item.requestTime}}</span>
          </v-expansion-panel-content>

          <v-expansion-panel-header style="margin:8px 0 12px 0">
            <div :class="`status-${item.status}`" style="font-size: 14px">
              {{ item.status | capitalize }}
            </div>
          </v-expansion-panel-header>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-data-table v-else
      :headers="headers"
      :hide-default-footer="true"
      fixed-header
      :items="pages"
      :loading="isLoading"
      height="300"
      loading-text="Loading... Please wait"
      no-data-text="No results found for your query"
    >
      <template v-slot:item.pageLink="{ item }">
        <a
          v-if="item.status === 'active'"
          :href="item.pageLink"
          style="color: #e2136e !important;"
          target="_blank"
        >
          {{ item.pageLink }}
        </a>
        <div v-else style="color: #777777">{{ item.pageLink }}</div>
        <v-btn
          v-if="item.status === 'active'"
          class="ma-2"
          text
          icon
          color="primary"
          @click="copyText(item.pageLink)"
        >
          <v-icon size="18">mdi-content-copy</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.status="{ item }">
        <div :class="`status-${item.status}`">
          {{ item.status | capitalize }}
        </div>
      </template>
      <template v-slot:item.pageId="{ item }">
        <v-btn
          depressed
          color="transparent"
          style="color: #e2136e;"
          @click="editPage(item.pageId, item.editable)"
        >
          <v-icon size="18">
            mdi-pencil
          </v-icon>
          Edit
        </v-btn>
      </template>
    </v-data-table>

    <pagination
      :is-disabled="isLoading"
      :item-count="pages.length"
      :tokens="tokens"
      @next="getNext"
      @previous="getPrevious"
    ></pagination>
  </div>
</template>

<script>
import Pagination from "cpp-js-util/components/common/Pagination";

export default {
  components: {
    Pagination,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width <= 1000;
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Page Title",
          value: "title",
          width: "17.5%",
          align: "start",
          sortable: false,
          class: "table-header-text",
        },
        {
          text: "Page Link",
          width: "32.5%",
          value: "pageLink",
          class: "table-header-text",
        },
        {
          text: "Date",
          width: "17.5%",
          value: "requestTime",
          class: "table-header-text",
        },
        {
          text: "Status",
          width: "10%",
          value: "status",
          class: "table-header-text",
        },
        {
          text: "Action",
          value: "pageId",
          width: "22.5%",
          class: "table-header-text",
        },
      ],
      pages: [],
      tokens: [],
      payload: { nextToken: "" },
      isLoading: true,
    };
  },
  mounted() {
    this.fetchPages();
  },
  methods: {
    editPage(pageId, editable) {
      if (editable === false) {
        this.$feedback.showErrorMessage("Sorry!", "Page cannot be edited!");
        return;
      }

      this.$feedback.showLoading();
      let body = {
        pageId: pageId,
      };

      this.$cppClient
        .post("/page/preview", body)
        .then((res) => {
          this.$feedback.hideLoading();
          this.$store.commit("setCustomPage", res.data);

          this.$router.push({ name: "custom-page" });
        })
        .catch(({ response }) => {
          console.log(response.data);
          this.$feedback.showFailed(response);
        });
    },
    async fetchPages() {
      this.isLoading = true;
      try {
        const res = await this.$cppClient.post("/page/list", this.payload);

        this.isLoading = false;
        this.pages = res.data.pages || [];
        this.tokens = ["", res.data.nextToken];
        console.log(res.data);
      } catch (err) {
        console.log(err.data);
        this.$feedback.showFailed(err);
      }
    },
    getNext(requestBody) {
      this.isLoading = true;
      this.$cppClient
        .post("/page/list", requestBody)
        .then(({ data }) => {
          this.isLoading = false;
          this.pages = data.pages;
          this.tokens.push(data.nextToken);
        })
        .catch(({ response }) => {
          this.isLoading = false;
          this.$logger.loggable(response).log();
          this.pages = [];
          this.tokens = [];
          this.$feedback.showFailed();
        });
    },
    getPrevious(requestBody) {
      this.isLoading = true;
      this.$cppClient
        .post("/page/list", requestBody)
        .then(({ data }) => {
          this.isLoading = false;
          this.pages = data.pages;
          this.tokens.pop();
        })
        .catch(({ response }) => {
          this.isLoading = false;
          this.$logger.loggable(response).log();
          this.pages = [];
          this.tokens = [];
          this.$feedback.showFailed();
        });
    },
    copyText(textToCopy) {
      const copied = navigator.clipboard.writeText(textToCopy);
      if (copied) {
        this.$feedback.showShortToast("Copied!");
      }
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-data-table.v-data-table--fixed-header thead th {
  background: #f9f9f9 !important;
  box-shadow: none !important;
}

.table-heading {
  background: #f9f9f9;
  border: none !important;
}

.table-heading-text {
  color: #777777 !important;
  font-size: clamp(12px, calc(12px + 0.32vw), 14px) !important;
  font-weight: normal !important;
  border-bottom: none !important;
}

.table-heading-text:first-of-type {
  padding-left: 20px !important;
}

.status-pending {
  color: #b3dc10;
}

.status-active {
  color: #01846C;
}

.status-inactive {
  color: #777;
}

>>> .v-expansion-panel-content__wrap {
  padding: 0;
}

>>> .v-expansion-panel-header {
  padding: 0;
}

>>> .v-expansion-panel-header {
  min-height: unset;
}

>>> .v-expansion-panel-header__icon > i {
  font-size: 16px;
}
</style>
