<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          color="#e2136e"
          height=52
          class="share-button">
        Share
        <v-icon right>
          mdi-share-variant
        </v-icon>
      </v-btn>
    </template>
    <v-card class="share-container">
      <div style="align-self: flex-end">
        <v-icon
            color="#000000"
            @click="dialog = false">
          mdi-close
        </v-icon>
      </div>

      <div class="title-container">
        <span class="title-text">
          Share your link on social media
        </span>
      </div>
      <v-card-actions class="item-container">
        <ShareNetwork
            network="whatsapp"
            :url="paymentLink"
            :title= "shareTitle"
            :description="shareDescription"
            :quote="shareQuote"
            :hashtags="shareHashtags"
        >
          <v-icon size="50" color="green darken-1" @click="onShareIconClick">mdi-whatsapp</v-icon>
        </ShareNetwork>
        <ShareNetwork
            network="facebook"
            :url="paymentLink"
            :title= "shareTitle"
            :description="shareDescription"
            :quote="shareQuote"
            :hashtags="shareHashtags"
        >
          <v-icon size="50" color="#3b5998" @click="onShareIconClick">mdi-facebook-box</v-icon>
        </ShareNetwork>
        <ShareNetwork
            network="messenger"
            :url="paymentLink"
            :quote="shareQuote"
            :hashtags="shareHashtags"
        >
          <v-icon size="50" color="#0078ff" @click="onShareIconClick">mdi-facebook-messenger</v-icon>
        </ShareNetwork>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LinkShare",
  data() {
    return {
      dialog: false,
    }
  },
  props: ['paymentLink'],
  computed : {
    shareTitle() {
      return process.env.VUE_APP_SHARE_TITLE;
    },
    shareDescription() {
      return process.env.VUE_APP_SHARE_DESCRIPTION;
    },
    shareQuote() {
      return process.env.VUE_APP_SHARE_QUOTE;
    },
    shareHashtags () {
      return process.env.VUE_APP_SHARE_HASHTAGS;
    },
  },
  methods: {
    onShareIconClick() {
      this.$gtag.event("Payment Link shared", {
        'event_category': (this.$route.name === 'dashboard')? "Dashboard Page" : "Payment Links Page"
      });
    },
  },
}
</script>

<style lang="scss" scoped>
$mobileW: 560px;
$mobileM: 475px;

.share-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 5px 40px 10px;

  .item-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 30px;
    justify-content: space-evenly;
    @media (max-width: $mobileW) {
      margin-top: 10px;
    }
  }

  .title-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;

    .title-text {
      font-size: 25px;
      justify-content: center;
      font-weight: 500;
      color: #e2136e;

      @media (max-width: $mobileW) {
        font-size: 15px;
      }
    }
  }

}

.share-button {
  width: 178px;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
  color: #FFFFFF;
  @media (max-width: $mobileW) {
    height: 40px !important;
  }
  @media (max-width: $mobileM) {
    width: 130px ;
  }
}

.v-btn--fab.v-size--default {
  height: 36px !important;
  width: 36px !important;
  @media (max-width: $mobileW) {
    height: 25px !important;
    width: 25px !important;
  }
}

.v-btn--fab.v-size--default.v-btn--absolute.v-btn--top {
  top: 10px !important;
}
</style>
