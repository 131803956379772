<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        persistent
        width="680"
    >
      <v-card>
        <custom-page-create-dialog-form :changeDialogStatus="closeDialog"></custom-page-create-dialog-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CustomPageCreateDialogForm from './CustomPageCreateDialogForm.vue';

export default {
  name: "CustomPageCreateDialog",
  components: {
    CustomPageCreateDialogForm
  },

  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    showUploadInfoDialog() {
      this.dialog = true;
    }
  },
  mounted() {
    this.$eventBus.$on('showCustomPageCreateDialog', () => {
      this.showUploadInfoDialog();
    });
  }

}
</script>

<style>
</style>
