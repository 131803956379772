<template>
  <div class="row " style="width: 100%; margin-left: 0; margin-bottom: 20px; margin-top: 15px">
    <div class="btn__container">
      <v-btn v-if="showPrevBtn"
             :disabled="isDisabled"
             @click="emitPrevious"
             outlined
             color="#e2136e"
             style="margin-right: 10px">
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Previous
      </v-btn>
      <v-btn
          :disabled="isDisabled"
          @click="emitNext"
          v-if="showNextBtn"
          outlined
          color="#e2136e">
        Next
        <v-icon
            right
            dark>
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </div>
    <v-spacer v-if="!isMobile"></v-spacer>
    <div v-if="itemCount > 0 && !isMobile">Showing items from {{ firstItemCursor }} to {{ lastItemCursor }}</div>
  </div>
</template>

<script>
export default {
  props: {
    tokens: Array,
    itemCount: Number,
    isDisabled: Boolean,
  },
  computed: {
    showNextBtn() {
      let lastToken = this.tokens[this.tokens.length - 1];
      let noDuplicateToken = this.tokens.filter(token => token === lastToken).length === 1;
      return (lastToken != null && this.itemCount > process.env.VUE_APP_PAGINATION_ITEM_COUNT)
          && noDuplicateToken;
    },
    showPrevBtn() {
      return this.tokens.length > 2;
    },
    pageIndex() {
      let pageCursor = this.tokens.length - 1;
      return (pageCursor > 0) ? pageCursor : 0;
    },
    firstItemCursor() {
      if (this.pageIndex <= 1) {
        return this.itemCount > 0 ? 1 : 0;
      } else {
        let prevPageIndex = this.pageIndex - 1;
        return (prevPageIndex * 10) + 1;
      }
    },
    lastItemCursor() {
      let lastDigit = this.itemCount % 10;
      if (this.pageIndex < 1) return this.itemCount;

      return (lastDigit === 0)
          ? this.pageIndex * 10
          : ((this.pageIndex - 1) * 10) + this.itemCount;
    },
    isMobile() {
      return this.$vuetify.breakpoint.width <= 1000;
    }
  },
  methods: {
    emitNext() {
      let nextToken = this.tokens[this.tokens.length - 1];
      let requestBody = (nextToken === '') ? {} : {nextToken: nextToken}
      this.$emit('next', requestBody)
    },
    emitPrevious() {
      let previousToken = this.tokens[this.tokens.length - 3];
      let requestBody  = (previousToken === '') ? {} : {nextToken: previousToken};
      this.$emit('previous', requestBody);
    }
  }
}
</script>
<style scoped lang="scss">
.btn__container{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  @media screen and (min-width: 1001px ) {
    flex-grow: unset;
    justify-content: unset;
  }
}
</style>