<template>
  <div class="payment-links">
    <SharePaymentLink
      :link="linkToCopy"
      :dialog="shareDialog"
      @close-dialog="closeDialog"
    ></SharePaymentLink>
    <section class="default">
      <div class="default-link-section-text">Your Default Payment Link</div>
      <v-divider v-if="isMobile" style="margin-top: 12px"></v-divider>
      <div class="default-link-options">
        <div class="default-payment-link">
          <v-text-field
            ref="textToCopy"
            :value="defaultPaymentLink"
            readonly
            filled
            dense
            solo
            flat
            background-color="#F5F5F5"
            class="default-payment-link-input"
          >
          </v-text-field>
          <button type="button" class="btn-copy" @click="copyPaymentLink">
            <img src="@/assets/image/copy-icon.svg" alt="" class="copy-icon" />
            Copy
          </button>
        </div>
        <div class="default-btns-container">
          <v-btn
            class="default-share-btn"
            style="height: 43px;"
            outlined
            color="primary"
            @click="sharePaymentLink(defaultPaymentLink)"
          >
            <v-icon>mdi-share</v-icon>
            <span> Share </span>
          </v-btn>
          <v-btn
            class="default-tap-to-know-btn"
            style="height: 43px;"
            outlined
            color="primary"
            @click="startTour"
          >
            <v-icon color="primary">mdi-help-circle-outline</v-icon>
            {{ isMobile ? "How it works" : "Tap to know how it works" }}
          </v-btn>
        </div>
      </div>
    </section>

    <div class="custom">
      <div style="display: flex;align-items: center;gap: 10px">
        <p class="default-link-section-text">Custom Payment Links</p>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              id="v-step-0"
              v-bind="attrs"
              v-on="on"
              class="icon-question-mark"
              >mdi-help-circle-outline</v-icon
            >
          </template>
          <span
            >Generate payment links with designated payment amounts and product
            descriptions
          </span>
        </v-tooltip>
      </div>

      <v-divider v-if="isMobile" style="margin-top: 12px"></v-divider>

      <div class="search-create-section">
        <search
          class="search"
          @search-payment-link="searchPaymentLink"
        ></search>
        <v-btn
          class="create-btn"
          height="42px"
          color="primary"
          @click="handleCreatePaymentLink"
        >
          <v-icon>mdi-pencil-box-multiple-outline</v-icon>
          Create New Link
        </v-btn>
      </div>

      <v-divider v-if="isMobile" style="margin: 8px 0 16px 0"></v-divider>

      <div class="links-list">
        <payment-link-list :searchString="searchText"></payment-link-list>
      </div>
    </div>
    <section class="custom">
      <CustomPageCard></CustomPageCard>
    </section>
    <v-tour name="paymentLinkTour" :steps="steps">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.steps[tour.currentStep]"
            :key="tour.currentStep"
            :finish="tour.finish"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :next-step="tour.nextStep"
            :previous-step="tour.previousStep"
            :skip="tour.skip"
            :step="tour.steps[tour.currentStep]"
            :stop="tour.stop"
            style="background-color: #d6588f; color: #ffffff; z-index: 9999999; !important"
          >
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
import Search from "@/components/paymentLinks/components/Search.vue";
import PaymentLinkList from "@/components/paymentLinks/components/PaymentLinkList.vue";
import CustomPageCard from "./CustomPageCard.vue";
import SharePaymentLink from "./components/SharePaymentLink.vue";

export default {
  name: "PaymentLinks",
  components: { PaymentLinkList, CustomPageCard, SharePaymentLink, Search },
  computed: {
    defaultPaymentLink() {
      return this.$store.getters.getPaymentLink;
    },
    isMobile() {
      return this.$vuetify.breakpoint.width <= 1000;
    },
  },
  data() {
    return {
      linkToCopy: null,
      showTips: false,
      shareDialog: false,
      searchText: "",
      searchRules: [
        (v) => !!v || "Search field is required", // Required validation
        (v) =>
          (v && v.length >= 3) || "Search text must be at least 3 characters", // Custom validation rule
      ],
      steps: [
        {
          target: "#v-step-0", // We're using document.querySelector() under the hood
          content: `Generate payment links with designated payment amounts and product
            descriptions`,
        },
        {
          target: "#v-step-1",
          content: "Create a Business Page for presenting your products.",
        },
      ],
    };
  },
  methods: {
    startTour() {
      this.$tours["paymentLinkTour"].start();
    },
    sharePaymentLink(link) {
      this.linkToCopy = link;
      this.shareDialog = true;
    },
    clearSearch() {
      this.searchText = "";
    },
    searchPaymentLink(searchText) {
      this.searchText = searchText;
      console.log("got here");
    },
    closeDialog() {
      console.log("got here");
      this.shareDialog = false;
      this.linkToCopy = null;
    },
    handleCreatePaymentLink() {
      this.$router.push({ name: "create-payment-link" });
    },
    copyPaymentLink() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector("input");
      textToCopy.select();
      let copied = document.execCommand("copy");
      if (copied) {
        this.$gtag.event("Default payment link copied", {
          event_category:
            this.$route.name === "dashboard"
              ? "Dashboard Page"
              : "Payment Links Page",
        });
        this.$feedback.showShortToast("Link Copied!");
      }
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}

.default-link-section-text {
  color: #464646;
  font-size: 14px;
  font-weight: 500;
}

.payment-links {
  margin: 72px 16px 16px 16px;
}

.default,
.custom {
  margin-top: 16px;
  padding: 16px;
  background-color: #fff;
}

.default-link-options {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.default-share-btn {
  width: 108px;
}

.default-btns-container {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.default-tap-to-know-btn {
  width: 180px;
}

.default-payment-link {
  display: flex;
  height: 44px;
}

.default-payment-link-input {
  width: 295px !important;
}

.default-payment-link-input:hover + .payment-link {
  display: block;
}

.default-payment-link-input:focus + .payment-link {
  display: block;
}

.default-payment-link-input >>> .v-input__slot {
  min-height: 42px !important;
  outline: 1px solid #dfdfdf;
}

.default-payment-link-input >>> .primary--text {
  color: green !important;
}

.btn-copy {
  background-color: #e2136e;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-left: -9px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  height: 42px;
  outline: 1px solid #e2136e;
  z-index: 100;
  padding-left: 10px;
  padding-right: 10px;
}

.copy-icon {
  margin-right: 7px;
}

.icon-question-mark {
  font-size: 16px;
}

.search-create-section {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.search {
  width: 100%;
}

.create-btn {
  width: 100%;
  height: 42px;
}

.text {
  /* width: 393px; */
  height: 100%;
  border: 1px solid #dedede;
  border-bottom-left-radius: inherit;
  border-top-left-radius: inherit;
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
}

.copy-btn {
  cursor: pointer;
  width: 25%;
  height: 100%;
  background-color: #e2136e;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.text .copy-btn {
  padding: 10px;
}

.share-tap {
  width: auto;
  height: 40px;
  padding: 0 10px;
  border: 1px solid #e2136e;
  border-radius: 4px;
  color: #e2136e;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.v-step {
  background: #1260cc;
  color: #ffffff;
}

@media screen and (min-width: 600px) {
  .default-link-section-text {
    font-size: 16px;
  }

  .search-create-section {
    flex-direction: row;
  }

  .search {
    max-width: 335px;
    width: 335px;
  }

  .create-btn {
    width: 195px;
  }
}

@media screen and (min-width: 768px) {
  .default-link-section-text {
    font-size: 18px;
  }
  .default-payment-link {
    width: 300px;
  }

  .default-btns-container {
    font-size: 14px;
    gap: 16px;
  }

  .default-link-options {
    flex-direction: row;
    gap: 16px;
  }

  .icon-question-mark {
    font-size: 20px;
  }
}

@media screen and (min-width: 1001px) {
  .payment-links {
    margin: 100px 40px 40px 40px;
  }

  .default,
  .custom {
    margin-top: 20px;
    padding: 32px;
  }

  .default-payment-link {
    width: 600px;
  }

  .default-link-section-text {
    font-size: 24px;
    font-weight: 600;
  }

  .default-link-options {
    flex-direction: row;
    gap: 24px;
  }

  .default-share-btn {
    width: 117px;
  }

  .default-tap-to-know-btn {
    width: 245px;
  }

  .default-btns-container {
    font-size: 16px;
    gap: 24px;
  }
}

@media screen and (max-width: 356px) {
  .default-share-btn {
    width: 75px;
  }

  .default-tap-to-know-btn {
    width: 146px;
  }
}
</style>
