<template>
  <v-form ref="form" v-model="isValid">
    <div class="page-create-form ">
      <span class="form_title"
            style="margin-top: 60px; margin-bottom: 20px;">Create Custom Page</span>
      <v-text-field
          v-model="page.title"
          :rules="inputRules"
          counter
          dense
          label="Enter Title"
          maxlength="28"
          outlined
          required
          @keydown="nameKeydown($event)"
          style="width: 80%"
      ></v-text-field>
      <div class="merchant-base-link">
        <div class="merchant-url-layout">
          <span class="merchant-url-text">{{ basePath }}</span>
        </div>
        <v-text-field
            v-model="page.urlFragment"
            :rules="basePathRules"
            dense
            counter
            label="Page Link"
            outlined
            placeholder="Link"
            required
            maxlength="24"
            type="text">
        </v-text-field>
      </div>

      <v-row style="margin-bottom: 50px; margin-top: 25px">
        <span class="cancel-button" @click="clearForm">Cancel</span>
        <v-btn :disabled="!isValid" color="#e2136e" style="color: #FFFFFF"
               @click="createPage">
          Create Page
        </v-btn>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import {URL_RULE, URL_BASE_PATH_RULE} from "cpp-js-util/CppUtil";

export default {
  name: "CustomPageCreateDialogForm",
  props: ['changeDialogStatus'],
  data() {
    return {
      page: {
        title: "",
        urlFragment: "",
      },
      isValid: false,
      inputRules: [
        v => !!v || 'Input is required',
      ],
      formData: null,
      basePathRules: [
        v => !!v || 'Page path is required',
        v => (v && v.length <= 24) || 'Page path cannot be more than 24 characters',
        v => URL_BASE_PATH_RULE.test(v) || 'Provide valid character only'
      ],
      generalRules: [
        v => !!v || 'Input is required',
      ],
      urlRules: [
        v => (v && v.length > 0 && !URL_RULE.test(v)) ? 'Provide valid url starting with https' : true
      ],
    }
  },
  mounted() {
    this.formData = new FormData();
  },
  computed: {
    basePath() {
      return this.$store.getters.getPageBasePath;
    },
  },
  watch: {
    'page.title'(val) {
      let filteredTitle = val.replace(/[^a-zA-Z0-9 ]/g, "");

      this.$nextTick(() => {
        this.page.title = filteredTitle;
      })
    },
  },
  methods: {
    nameKeydown(e) {
      if (!/^[a-zA-Z0-9 ]*$/.test(e.key)) {
        e.preventDefault();
      }
    },
    clearForm() {
      this.page = {
        title: "",
        urlFragment: "",
      };
      this.$refs.form.reset();
      this.changeDialogStatus();
    },
    createPage() {
      this.$feedback.showLoading();

      var formData = new FormData();
      formData.append("title", this.page.title);
      formData.append("urlFragment", this.page.urlFragment);

      this.$cppClient.post('/page/create', formData, null, true)
          .then(res => {
            this.$feedback.hideLoading();
            this.$store.commit('setCustomPage', res.data);
            this.$router.push({name: 'custom-page'});
          })
          .catch(({response}) => {
            this.$feedback.hideLoading();
            this.$feedback.showFailed(response);
          })
          .finally(() => {
            this.clearForm();
          })
    },
  }
}
</script>

<style>
.merchant-url-layout {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  display: flex;
  height: 40px;
  border: solid 1px #dddddd;
  background-color: #dddddd;
  align-items: center;
}

.merchant-base-link {
  display: flex;
  flex-direction: row;
  width: 80%;
}

.merchant-url-text {
  margin-left: 6px;
  margin-right: 3px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #9a9a9a;
  white-space: nowrap;
}

.page-create-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form_title {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.82;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}

@media only screen and (max-width: 500px) {
  .merchant-url-layout{
    overflow-x: clip;
  }
  .merchant-base-link{
    flex-direction: column;
  }
  .merchant-url-text{
    font-size: 13px;
    word-break: break-all;
  }
}
</style>
