<template>
  <v-card class="page-container" elevation="0">
    <custom-page-create-dialog></custom-page-create-dialog>

    <section class="header-group">
      <div style="display: flex; align-items: center;gap: 32px">
        <img
          :src="customPageIcon"
          alt="Custom page icon"
          class="custom-page-icon"
        />
        <div>
          <h2 class="header">
            Create Your Personalized Page
          </h2>
          <p class="sub-header">
            Now you can create your own page with your personalized way with
            FREE of cost.
          </p>
        </div>
      </div>
      <v-btn
        outlined
        color="#E2136E"
        class="cta-btn"
        @click="showCustomPageCreateDialog"
      >
        Create Page
      </v-btn>
    </section>

    <v-divider></v-divider>
    <br />

    <div class="page-list-header-container">
      <p class="custom-page-list-header">Your Pages</p>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            id="v-step-1"
            v-bind="attrs"
            v-on="on"
            class="icon-question-mark"
            >mdi-help-circle-outline</v-icon
          >
        </template>
        <span>Create a Business Page for presenting your products. </span>
      </v-tooltip>
    </div>

    <page-list style="margin-top: 18px;"></page-list>
  </v-card>
</template>

<script>
import CustomPageIcon from "@/assets/image/custom-page-icon.svg";
import CustomPageCreateDialog from "@/components/pageManagement/customPage/CustomPageCreateDialog";
import PageList from "@/components/pageManagement/PageList";

export default {
  name: "CustomPageCardVue",
  components: {
    CustomPageCreateDialog,
    PageList,
  },
  data() {
    return {
      customPageIcon: CustomPageIcon,
    };
  },
  methods: {
    showCustomPageCreateDialog() {
      this.$eventBus.$emit("showCustomPageCreateDialog");
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0 !important;
}

.page-container {
  border-radius: 4px;
}

.header-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.header {
  font-weight: 500;
  font-size: 24px;
  color: #464646;
  margin-bottom: 16px;
}

.sub-header {
  font-weight: 400;
  font-size: 18px;
  color: #464646;
}

.custom-page-icon {
  width: 156px;
  height: 100px;
}

.cta-btn {
  font-size: 18px;
  font-weight: 400;
  border-radius: 4px;
  height: 42px;
  width: 244px;
  padding: 11px 89px !important;
}

.page-list-header-container {
  display: inline-flex;
  gap: 16px;
}

.custom-page-list-header {
  color: #464646;
  font-size: 18px;
  font-weight: 500;
}

.custom-page-list-icon {
  font-size: 20px;
}

@media screen and (max-width: 1295px) {
  .header {
    font-size: 22px;
  }

  .sub-header {
    font-size: 16px;
  }
}

@media screen and (max-width: 1124px) {
  .header {
    font-size: 20px;
  }

  .sub-header {
    font-size: 16px;
  }
}

@media screen and (max-width: 1000px) {
  .custom-page-icon {
    display: none;
  }

  .header-group {
    width: 100%;
    flex-direction: column;
    align-items: start;
  }

  .header-group br {
    display: none;
  }

  .cta-btn {
    font-size: 14px;
    height: 36px;
    width: 143px;
    padding: 10px 32px !important;
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .header {
    font-size: 16px;
  }

  .sub-header {
    font-size: 14px;
  }

  .page-list-header-container {
    gap: 12px;
  }

  .custom-page-list-header {
    font-size: 16px;
  }

  .custom-page-list-icon {
    font-size: 18px;
  }
}
</style>
