<template>
  <v-text-field
    v-model="searchText"
    label="Search"
    placeholder="Search by link ID or Name"
    prepend-inner-icon="mdi-magnify"
    @keyup.enter="handleInput"
    @input="handleInput"
    :append-icon="searchText ? 'mdi-close' : ''"
    @click:append="clearSearch"
    single-line
    hide-details
    class="search"
  ></v-text-field>
</template>

<script>
export default {
  name: "Search",
  data() {
    return {
      searchText: "",
      searchRules: [
        (v) => !!v || "Search field is required",
        (v) =>
          (v && v.length >= 3) || "Search text must be at least 3 characters",
      ],
    };
  },
  methods: {
    handleInput() {
      this.$emit("search-payment-link", this.searchText);
    },
    clearSearch() {
      this.searchText = "";
      this.$emit("search-payment-link", this.searchText);
    },
  },
};
</script>

<style scoped>
.search {
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.v-text-field {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

>>> .v-input {
  margin-top: 0 !important;
}

>>> .v-input__slot {
  padding: 5px;
}

>>> .v-input__slot::before {
  border-style: none !important;
}
</style>
